import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { StudentSessionState } from 'src/app/features/cycles/store/student-session/student-session.state';

import {
  LivErrorResponse,
  LivSuccessResponse
} from '../models/liv-response-protocol.model';
import { SessionState } from '../store/session/session.state';

const loginAuthPath = '/auth/local';
const refreshTokenAuthPath = '/auth/refresh';
const assetsPath = '/assets/';
const cyclePath = '/cycle/family/';

const pathsWithNoNeedsAuthToken = [
  loginAuthPath,
  refreshTokenAuthPath,
  assetsPath,
  cyclePath
];

export function requestNeedsAuthToken(req: HttpRequest<any>): boolean {
  return !new RegExp(pathsWithNoNeedsAuthToken.join('|')).test(req.url);
}

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private store: Store) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<LivSuccessResponse | LivErrorResponse | unknown>> {
    const token =
      this.store.selectSnapshot(SessionState.accessToken) ||
      this.store.selectSnapshot(StudentSessionState.token);

    if (token && requestNeedsAuthToken(req)) {
      return next.handle(
        req.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          }
        })
      );
    }

    return next.handle(req);
  }
}
