import { registerLocaleData } from '@angular/common';
import {
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import localeBr from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule, Optional, SkipSelf, inject } from '@angular/core';
import {
  NgIconsModule,
  provideNgIconLoader,
  withCaching
} from '@ng-icons/core';
import {
  featherArrowLeft,
  featherArrowRight,
  featherChevronRight,
  featherEdit3,
  featherX
} from '@ng-icons/feather-icons';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { GlobalErrorHandler } from './errors/GlobalErrorHandler';
import { ErrorHandlerService } from './errors/services/error-handler.service';
import { httpInterceptorProviders } from './interceptors';
import { ApiGatewayService } from './services/api-gateway.service';
import { StorageService } from './services/storage.service';
import { TimerService } from './services/timer.service';
import { ToastService } from './services/toast.service';
import { WindowEventsService } from './services/window-events.service';
import { WindowResizeService } from './services/window-resize.service';

registerLocaleData(localeBr, 'pt-BR');

@NgModule({
  imports: [
    AngularSvgIconModule.forRoot(),
    NgIconsModule.withIcons({
      featherEdit3,
      featherChevronRight,
      featherX,
      featherArrowLeft,
      featherArrowRight
    })
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR'
    },
    httpInterceptorProviders,
    ApiGatewayService,
    StorageService,
    ErrorHandlerService,
    GlobalErrorHandler,
    ToastService,
    TimerService,
    WindowResizeService,
    WindowEventsService,
    provideHttpClient(withInterceptorsFromDi()),
    provideNgIconLoader((name) => {
      const http = inject(HttpClient);
      return http.get(`/assets/icons/${name}.svg`, { responseType: 'text' });
    }, withCaching())
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only'
      );
    }
  }
}
