import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  LivErrorResponse,
  LivSuccessResponse
} from '../models/liv-response-protocol.model';

const whiteList = ['/assets', '/user/avatar'];

@Injectable()
export class ContentTypeInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<LivSuccessResponse | LivErrorResponse | unknown>> {
    const whiteListed = new RegExp(whiteList.join('|')).test(req.url);

    if (whiteListed || req.headers.has('x-skip-interceptor')) {
      const headers = req.headers.delete('x-skip-interceptor');
      const clonedRequest = req.clone({ headers });
      return next.handle(clonedRequest);
    }
    const allowedFormDataMethods = ['POST', 'PUT', 'PATCH'];

    if (
      allowedFormDataMethods.includes(req.method) &&
      req.body instanceof FormData
    ) {
      const request = req.clone({
        headers: req.headers.delete('Content-Type')
      });

      return next.handle(request);
    }

    const request = req.clone({
      setHeaders: {
        'Content-Type': 'application/json'
      }
    });

    return next.handle(request);
  }
}
