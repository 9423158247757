import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpStatusCode
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { RemoveStudentSessionAction } from 'src/app/features/cycles/store/student-session/student-session.action';
import { StudentSessionState } from 'src/app/features/cycles/store/student-session/student-session.state';
import { shouldRetry } from 'src/app/shared/rxjs/should-retry';

import {
  LivErrorResponse,
  LivResponseProtocol,
  LivSuccessResponse
} from '../models/liv-response-protocol.model';
import { ToastService } from '../services/toast.service';
import { LogoutAction } from '../store/session/session.actions';

const EXCLUDED_STATUS_CODES_ON_RETRY = [
  HttpStatusCode.NotFound,
  HttpStatusCode.Forbidden,
  HttpStatusCode.Unauthorized,
  HttpStatusCode.NotFound,
  HttpStatusCode.UnprocessableEntity
];

const retryConfig = {
  maxRetryAttempts: 1,
  scalingDuration: 3000,
  excludedStatusCodes: EXCLUDED_STATUS_CODES_ON_RETRY
} as const;

@Injectable()
export class ResponseProtocolInterceptor implements HttpInterceptor {
  constructor(
    private toast: ToastService,
    private store: Store
  ) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<LivSuccessResponse | LivErrorResponse>> {
    return next.handle(req).pipe(
      map((event) => this.handleResponseEvent(event)),
      retry(shouldRetry(retryConfig)),
      catchError((err: HttpErrorResponse) => this.handleError(err))
    );
  }

  private handleResponseEvent(
    event: HttpEvent<unknown>
  ): HttpEvent<LivSuccessResponse | LivErrorResponse> {
    if (event instanceof HttpResponse) {
      return event.clone({ body: this.handleResponse(event) });
    }
    return event;
  }

  handleResponse(
    event: HttpResponse<unknown>
  ): LivSuccessResponse | LivErrorResponse {
    const response = event.body as LivResponseProtocol;

    if (
      event.url?.includes('assets') ||
      event.url?.includes('google') ||
      event.body instanceof Blob
    ) {
      return event.body as never;
    }

    if (response.error) {
      throw new HttpErrorResponse({
        status: response.status ?? event.status,
        error: response.error,
        url: event.url ?? ''
      });
    }

    if ('data' in response) {
      return {
        status: response.status ?? event.status,
        data: response.data,
        meta: response.meta
      };
    }

    throw new HttpErrorResponse({
      status: event.status,
      error: event.body,
      url: event.url ?? ''
    });
  }

  private handleError(response: HttpErrorResponse): Observable<never> {
    if (response.status === HttpStatusCode.InternalServerError) {
      return this.handleServerError(response);
    }

    if (response.status === HttpStatusCode.Unauthorized) {
      return this.handleUnauthorizedError(response);
    }

    return throwError(() => this.createErrorResponse(response));
  }

  private handleUnauthorizedError(
    response: HttpErrorResponse
  ): Observable<never> {
    const hasStudentSession = this.store.selectSnapshot(
      StudentSessionState.token
    );

    this.toast.error('Sua sessão expirou. Faça login novamente.');

    if (hasStudentSession) {
      this.store.dispatch(new RemoveStudentSessionAction());

      return throwError(() => this.createErrorResponse(response));
    }

    this.store.dispatch(new LogoutAction());

    return throwError(() => this.createErrorResponse(response));
  }

  private handleServerError(response: HttpErrorResponse): Observable<never> {
    let message = response.error?.message as string;

    if (!message || message.includes('stacktrace')) {
      message = 'Ocorreu um erro inesperado! Tente novamente mais tarde.';
    }

    this.toast.error(message);
    return throwError(() => this.createErrorResponse(response));
  }

  createErrorResponse(response: HttpErrorResponse): LivErrorResponse {
    const errorResponse: LivErrorResponse = {
      status:
        (
          response.error as {
            status: HttpStatusCode;
          }
        )?.status || response.status,
      error: {
        ...response.error,
        stack: {
          url: response.url
        }
      }
    };

    return errorResponse;
  }
}
