import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, catchError, map, of, switchMap } from 'rxjs';
import { LoadingOverlayService } from 'src/app/shared/components/loading-overlay/loading-overlay.service';

import { LoginAction } from '../../store/session/session.actions';
import { SessionState } from '../../store/session/session.state';

@Injectable({
  providedIn: 'root'
})
export class InterceptorGuard {
  constructor(
    private router: Router,
    private store: Store,
    private loadingOverlayService: LoadingOverlayService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const guestKey = route.queryParamMap.get('guestKey');

    return this.authenticate$(guestKey);
  }

  private authenticate$(
    guestKey: string | null
  ): Observable<boolean | UrlTree> | UrlTree {
    if (!guestKey) {
      return this.router.createUrlTree(['404']);
    }

    this.loadingOverlayService.open();

    return this.store.select(SessionState.isAuthenticated).pipe(
      switchMap((isAuthenticated) => {
        return isAuthenticated ? of(true) : this.login$(guestKey);
      })
    );
  }

  private isAuthenticated(): boolean {
    return this.store.selectSnapshot(SessionState.isAuthenticated);
  }

  private handleLoginError(): Observable<{ redirect: string }> {
    this.loadingOverlayService.remove();
    return of({ redirect: '401' });
  }

  private getRedirectUrl(redirect: string): boolean | UrlTree {
    return (
      this.isAuthenticated() || this.router.createUrlTree([redirect || '401'])
    );
  }

  login$(guestKey: string): Observable<boolean | UrlTree> {
    return this.store.dispatch(new LoginAction({ guestKey })).pipe(
      catchError(() => this.handleLoginError()),
      map((result: void | { redirect: string }) => {
        const redirect = result ? result.redirect : '401';
        return this.getRedirectUrl(redirect);
      })
    );
  }
}
