import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { InterceptorGuard } from './core/security/guards/interceptor.guard';

const routes: Routes = [
  {
    path: 'livro-do-professor',
    canActivate: [InterceptorGuard],
    loadChildren: () =>
      import('./features/teacher-book/teacher-book.module').then(
        (m) => m.TeacherBookModule
      )
  },
  {
    path: '404',
    loadComponent: () =>
      import('./pages/not-found/not-found.component').then(
        (m) => m.NotFoundComponent
      )
  },
  {
    path: '401',
    loadComponent: () =>
      import('./pages/unauthenticated/unauthenticated.component').then(
        (m) => m.UnauthenticatedComponent
      )
  },
  {
    path: '**',
    redirectTo: '/404'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
