import { registerLocaleData } from '@angular/common';
import localePtExtra from '@angular/common/locales/extra/pt';
import localePt from '@angular/common/locales/pt';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideHotToastConfig } from '@ngneat/hot-toast';
import {
  LOCAL_STORAGE_ENGINE,
  StorageOption,
  withNgxsStoragePlugin
} from '@ngxs/storage-plugin';
import { NgxsModule, provideStore } from '@ngxs/store';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { CoreModule } from './core/core.module';
import { decrypt, encrypt } from './core/security/state-cypher';
import { ConfigService } from './core/services/config.service';
import { ActivityState } from './core/store/activity/activity.state';
import { BookState } from './core/store/book/book.state';
import { CategoryState } from './core/store/category/category.state';
import { KibanaState } from './core/store/kibana/kibana.state';
import { PageState } from './core/store/page/page.state';
import { SessionState } from './core/store/session/session.state';
import { SurveyState } from './core/store/survey/survey.state';
import { StudentState } from './features/cycles/store/student/student.state';
import { StudentSchoolsState } from './features/cycles/store/student-schools/student-schools.state';
import { StudentSessionState } from './features/cycles/store/student-session/student-session.state';

export const configFactory = (configService: ConfigService) => {
  return () => configService.loadConfig$();
};

registerLocaleData(localePt, 'pt-BR', localePtExtra);

const PERSISTED_STATES = [BookState, SessionState, CategoryState, PageState];

const STATES = [
  ...PERSISTED_STATES,
  KibanaState,
  SurveyState,
  ActivityState,
  StudentSchoolsState,
  StudentSessionState,
  StudentState
];

@NgModule({
  declarations: [AppComponent],
  providers: [
    ConfigService,
    provideNgxMask({
      validation: false
    }),
    provideStore(
      [...STATES],
      withNgxsStoragePlugin({
        serialize: (value: any) =>
          environment.production ? encrypt(value) : JSON.stringify(value),
        deserialize: (value: string) =>
          environment.production ? decrypt(value) : JSON.parse(value),
        keys: [
          {
            key: StudentSessionState,
            engine: LOCAL_STORAGE_ENGINE
          },
          ...PERSISTED_STATES
        ],
        storage: StorageOption.SessionStorage,
        namespace: '@liv-famly-pfo'
      })
    ),
    {
      provide: APP_INITIALIZER,
      useFactory: configFactory,
      deps: [ConfigService],
      multi: true
    },
    provideHotToastConfig()
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    AngularSvgIconModule,
    NgxsModule.forRoot(STATES, {
      developmentMode: !environment.production,
      selectorOptions: {
        injectContainerState: false // https://www.ngxs.io/advanced/options (injectContainerState false é recomendado p/ melhor performance)
      }
    }),
    NgxMaskDirective
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
