import { registerLocaleData } from '@angular/common';
import {
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import localeBr from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule, Optional, SkipSelf, inject } from '@angular/core';
import {
  NgIconsModule,
  provideNgIconLoader,
  withCaching
} from '@ng-icons/core';
import {
  featherArrowLeft,
  featherArrowRight,
  featherChevronRight,
  featherEdit3,
  featherX
} from '@ng-icons/feather-icons';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { environment } from 'src/environments/environment';

import { GlobalErrorHandler } from './errors/GlobalErrorHandler';
import { ErrorHandlerService } from './errors/services/error-handler.service';
import { httpInterceptorProviders } from './interceptors';
import { decrypt, encrypt } from './security/state-cypher';
import { ApiGatewayService } from './services/api-gateway.service';
import { StorageService } from './services/storage.service';
import { TimerService } from './services/timer.service';
import { ToastService } from './services/toast.service';
import { WindowEventsService } from './services/window-events.service';
import { WindowResizeService } from './services/window-resize.service';
import { ActivityState } from './store/activity/activity.state';
import { BookState } from './store/book/book.state';
import { CategoryState } from './store/category/category.state';
import { KibanaState } from './store/kibana/kibana.state';
import { PageState } from './store/page/page.state';
import { SessionState } from './store/session/session.state';
import { SurveyState } from './store/survey/survey.state';

registerLocaleData(localeBr, 'pt-BR');

const PERSISTED_STATES = [BookState, SessionState, CategoryState, PageState];
const STATES = [...PERSISTED_STATES, KibanaState, SurveyState, ActivityState];

@NgModule({
  imports: [
    AngularSvgIconModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      serialize: (value: any) =>
        environment.production ? encrypt(value) : JSON.stringify(value),
      deserialize: (value: string) =>
        environment.production ? decrypt(value) : JSON.parse(value),
      storage: StorageOption.SessionStorage,
      namespace: '@liv-famly-pfo',
      keys: [...PERSISTED_STATES]
    }),
    NgxsModule.forRoot(STATES, {
      developmentMode: !environment.production,
      selectorOptions: {
        injectContainerState: false // https://www.ngxs.io/advanced/options (injectContainerState false é recomendado p/ melhor performance)
      }
    }),
    NgIconsModule.withIcons({
      featherEdit3,
      featherChevronRight,
      featherX,
      featherArrowLeft,
      featherArrowRight
    })
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR'
    },
    httpInterceptorProviders,
    ApiGatewayService,
    StorageService,
    ErrorHandlerService,
    GlobalErrorHandler,
    ToastService,
    TimerService,
    WindowResizeService,
    WindowEventsService,
    provideHttpClient(withInterceptorsFromDi()),
    provideNgIconLoader((name) => {
      const http = inject(HttpClient);
      return http.get(`/assets/icons/${name}.svg`, { responseType: 'text' });
    }, withCaching())
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only'
      );
    }
  }
}
